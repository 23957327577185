/*
 *     DOM elements
 */

let loadMoreNotificationsButtonDOM = '.notify-more';
let notificationDOM = '.notify-notification';
let notificationsDOM = '.notify-notifications';
let iconBadgeDOM = '.notify-icon-badge';
let notifyDropDOM = '.notify-drop';
let readUnreadIconDOM = '.rIcon';
let secondaryButtonDOM = '.notify-btn';
let readAllDOM = '.notify-read-all';

/*
 *     Classes, attributes and icons
 */

let offsetAttribute = 'data-offset';
let actionAttribute = 'data-action';
let disabledClass = 'disabled';
let unreadClass = 'notify-unread';
let noActionClass = 'no-action';
let clickableClass = 'clickable';
let multipleActionsPossibleClass = 'multiple-actions-possible'
let unreadIcon = 'far fa-circle';
let readIcon = 'far fa-dot-circle';

/*
 *     General stuff
 */

$(document).on('click', notifyDropDOM, function (e) {
    e.stopPropagation();
});

$('#notify-dropdown').on('shown.bs.dropdown', function () {
    changeStateOfAllNoActionNotificationsToRead();
});

$(document).ready(function() {
    if($('#notify-dropdown').length)
        loadNotifications();
});

function loadNotifications(){
    $(loadMoreNotificationsButtonDOM).addClass(disabledClass);
    $.getJSON(getNotificationsURL())
        .done(function(data) {
            if(data.isLastPage)
                $(loadMoreNotificationsButtonDOM).hide();
            setNumberOfUnreadNotifications(data.numberOfUnreadNotifications);
            $(loadMoreNotificationsButtonDOM).attr(offsetAttribute, data.offset + 1);
            $.each(data.notifications, function(key, notification) {
                $(notificationsDOM).append(createNotification(notification));
            });
            if($('#notify-dropdown').hasClass("show")) //The dropdown is open so automatically mark no-action notifications as read
                changeStateOfAllNoActionNotificationsToRead();
        })
        .fail(function(){
            createMessage(`Notifications could not be loaded. Please try again later and contact the developer when this error keeps popping up.`,'danger')
        })
        .always(function(){
            $(loadMoreNotificationsButtonDOM).removeClass(disabledClass);
            loadOnClickHandlers();
        })
}

function createMessage(message, type){
    $('.main-container').prepend(`<div class="alert alert-${type}">
        ${message}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>`);
}

/*
 *     On click
 */
/* Load more notifications */
$(loadMoreNotificationsButtonDOM).click(function() {
    if(!$(loadMoreNotificationsButtonDOM).hasClass(disabledClass))
        loadNotifications();
});

function loadOnClickHandlers() {
    /* Toggle state */
    $(readUnreadIconDOM).click(function (e) {
        if($(this).closest(notificationDOM).length) {
            console.log($(this));
            console.log($(this).closest(notificationDOM));
            let id = getNotificationId($(this).closest(notificationDOM));
            toggleNotificationState(id);
        } else if($(this).closest(readAllDOM).length){
            $(notificationDOM+'.'+unreadClass).each(function(){
                let id = getNotificationId($(this));
                changeNotificationState(id, true);
            });
        }
        return false;
    });

    /* Primary action */
    $(notificationDOM).click(function(){
        let id = getNotificationId($(this));
        markNotificationAsRead(id);
        return true; //default a href
    });
    $(notificationDOM).mousedown(function(e){
        if(e.which !== 2) //middle mouse button
            return true;
        let id = getNotificationId($(this));
        markNotificationAsRead(id);
        return true; //default a href
    });

    /* Secondary action */
    $(secondaryButtonDOM).click(function () {
        let id = getNotificationId($(this).closest(notificationDOM));
        executeSecondaryAction(id, $(this).attr(actionAttribute));
        return false;
    });
}

/*
 *     Getters and setters
 */
function getNotificationId(element){
    return $(element).attr('id').substr('13'); //'notification-xx' - 'notification-' = 'xx'
}

function getNumberOfUnreadNotifications(){
    return $(iconBadgeDOM).attr('data-count');
}

function setNumberOfUnreadNotifications(numberOfUnreadNotifications){
    $(iconBadgeDOM).attr('data-count', numberOfUnreadNotifications);
}

function getLocale(){
    return $('html').attr('lang');
}

function getNotificationDOM(notificationId){
    return $('#notification-' + notificationId);
}

/*
 *     Creation of notification
 */
function createNotification(notification){
    let string =  `
    <li id="notification-${notification.id}" class="notify-notification ${createClassesForNotification(notification)}">  
        <div class="row">
            <div class="col-2 notify-img">
                <i class="${notification.icon} fa-2x"></i>
            </div>
            <div class="col-8">
                <p>${notification.text}</p>
                ${createNotificationSecondaryActions(notification)}
            </div>
            <div class="col-2">
                <span class="rIcon clickable"><i class="${createReadIconForNotification(notification.isActionRequired, notification.isRead)}"></i></span>
                <br/>
                <div class="notify-time"><p>${notification.timestamp}</p></div>
            </div>
        </div>
    </li>`;

    if(notification.canExecutePrimaryAction)
        return `<a href="${getActionURLForNotification(notification.id, null)}" class="hyperlink-no-style">${string}</a>`;
    else
        return string;
}

function createClassesForNotification(notification){
    let classes = '';
    if(!notification.isRead)
        classes += unreadClass + ' ';
    if(!notification.isActionRequired)
        classes += noActionClass + ' ';
    if(notification.canExecutePrimaryAction)
        classes += clickableClass + ' ';
    if(notification.canExecuteSecondaryEventMultipleTimes)
        classes += multipleActionsPossibleClass + ' ';
    return classes;
}

function createReadIconForNotification(actionRequired, isRead){
    if(!actionRequired)
        return '';
    if(isRead)
        return readIcon;
    return unreadIcon;
}

function createNotificationSecondaryActions(notification){
    let html = '';
    if(!notification.canExecuteSecondaryActions)
        return html;
    $.each(notification.actions, function(key, action) {
        html += createNotificationSecondaryAction(notification, action);
    });
    return html;
}

function createNotificationSecondaryAction(notification, action){
    return `<p class="notify-btn clickable" data-action="${action.id}">${action.buttonName}</p>`;
}

function executeSecondaryAction(notificationId, actionId){
    $.getJSON(getActionURLForNotification(notificationId, actionId))
        .done(function(data) {
           if(data.status === 'error')
               createMessage(data.error.message,'danger');
        })
        .fail(function(){
            createMessage(`An error occurred while executing the secondary action \'${actionId}\'. Please try again later and contact the developer when this error keeps popping up.`,'danger')
        });
    hideSecondaryActionsIfNecessary(notificationId);
    markNotificationAsRead(notificationId); //Could be done in the 'done' method, but it will be much slower then.
}

function hideSecondaryActionsIfNecessary(notificationId){
    notificationDOM = getNotificationDOM(notificationId);
    if($(notificationDOM).hasClass(multipleActionsPossibleClass))
        return;
    $(notificationDOM).find(secondaryButtonDOM).each(function(index) {
        $(this).hide('slow');
    })
}


/*
 *     Change read state
 */
/* Server side methods */
function toggleNotificationState(notificationId){
    let currentStateIsRead = isNotificationMarkedAsRead(notificationId);
    if(currentStateIsRead)
        changeNotificationState(notificationId, false);
    else
        changeNotificationState(notificationId, true);
}

function isNotificationMarkedAsRead(notificationId){
    return !$('#notification-'+notificationId).hasClass(unreadClass);
}

function changeNotificationState(notificationId, isRead){
    $.getJSON(getActionURLForReadStateForNotification(notificationId, isRead))
        .done(function(data) {
            if(data.status === 'error')
                createMessage(data.error.message,'danger');
        })
        .fail(function(){
            createMessage(`An error occurred while trying to mark the notification as read/unread. Please try again later and contact the developer when this error keeps popping up.`,'danger')
        });
    markNotificationAsReadOrUnread(notificationId, isRead); //Could be done in the 'done' method, but it will be much slower then.
}

function changeStateOfAllNoActionNotificationsToRead(){
    $(notificationDOM+'.'+noActionClass+'.'+unreadClass).each(function (){
        let notification = $(this);
        let id = getNotificationId($(notification));
        changeNotificationState(id, true);
        $(notification).css({
            "background-color": "#f5f5f5"
        });
        window.setTimeout( function() {
            $(notification).css({
                "transition" : "background-color 1s ease-in-out",
                "background-color": "#e8d0d0"
            });
            window.setTimeout( function() {
                $(notification).css({
                    "transition" : "background-color 1s ease-in-out",
                    "background-color": "inherit"
                });

            }, 1000);
        }, 50);

    });
}

/*front end site methods*/
function markNotificationAsRead(notificationId){
    markNotificationAsReadOrUnread(notificationId, true);
}

function markNotificationAsReadOrUnread(notificationId, isRead){
    let notification =  $('#notification-'+notificationId);
    let wasRead = !notification.hasClass(unreadClass);
    if(wasRead === isRead)
        return;
    let numberOfUnreadNotifications = getNumberOfUnreadNotifications();
    if(isRead) {
        notification.removeClass(unreadClass);
        setNumberOfUnreadNotifications(--numberOfUnreadNotifications);
    }
    else {
        notification.addClass(unreadClass);
        setNumberOfUnreadNotifications(++numberOfUnreadNotifications);

    }
    notification.find(readUnreadIconDOM).html(`<i class="${createReadIconForNotification(!notification.hasClass(noActionClass), isRead)}"></i>`);
}



/*
 *     URLs
 */
function getActionURLForNotification(notificationId, actionId){
    let url = '/'+getLocale()+'/notification/action/' + notificationId;
    if(actionId == null)
        return url; //secondary
    return url+'/'+actionId; //primary
}

function getNotificationsURL(){
    let offset = $(loadMoreNotificationsButtonDOM).attr(offsetAttribute);
    return '/' + getLocale() + "/notifications/" + offset
}

function getActionURLForReadStateForNotification(notificationId, isRead){
    return '/' + getLocale() + "/notification/state/" + notificationId + '/' + +isRead; //typecast to (0 / 1)
}