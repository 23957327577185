$(document).ready(function() {
    var automaticRefreshCheckbox = $("#settings_parameters_form_shouldAutomaticallyRefresh");
    var refreshIntervalInput = $('#settings_parameters_form_refreshInterval');
    if(automaticRefreshCheckbox !== undefined) {
        refreshIntervalInput.prop('readOnly', !automaticRefreshCheckbox.is(':checked'));
        automaticRefreshCheckbox.change(function () {
            refreshIntervalInput.prop('readOnly', !$(this).is(':checked'));
        });
    }

    var refreshCounter = $('#refresh-counter');
    if(refreshCounter !== undefined)
        checkInterval(refreshCounter.html());
});

function checkInterval(interval){
    if(interval === 0)
        location.reload();
    else {
        setTimeout(function () {
            checkInterval(interval-1)
        }, 1000);
        $('#refresh-counter').html(interval-1);
    }
}